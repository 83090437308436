import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Title from "./Title"

const query = graphql`
  {
    allPrismicHeadlessCms(sort: { fields: data___number }) {
      nodes {
        data {
          image {
            alt
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
          }
        }
      }
    }

    allPrismicStaticSiteGenerator {
      nodes {
        data {
          image {
            alt
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
          }
        }
      }
    }

    allPrismicDeploymentPlatform {
      nodes {
        data {
          image {
            alt
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const TechnologiesWeUse = () => {
  const {
    allPrismicHeadlessCms: { nodes: headlessCmsLogos },
    allPrismicStaticSiteGenerator: { nodes: staticSiteGenerator },
    allPrismicDeploymentPlatform: { nodes: deploymentPlatform },
  } = useStaticQuery(query)

  return (
    <Wrapper className="section">
      <div className="technologies row section u-margin-top u-margin-bottom-medium">
        <div className="u-header-wrap u-margin-bottom-big">
          <div className="row">
            <div className="col-1-of-1">
              <Title title="Technologies I Use" />
              <h2 className="heading-primary heading-primary--main">
                Every project is unique. I pick a committed set of tools for
                each one, for the best performance possible. Here are just a few
                of the tools I’ve worked on within the past.
              </h2>
            </div>
          </div>
        </div>

        <section className="technologie-brand-logos u-center-text">
          <div className="row u-no-margin-bottom">
            <div className="col-1-of-1 u-margin-top-small">
              <Title title="Headless CMS" />
            </div>
          </div>

          <div className="row">
            <div className="custom-colomn">
              {headlessCmsLogos.map(headlessCmsLogo => {
                const cmsLogo = headlessCmsLogo.data.image.fluid
                const cmsLogoAlt = headlessCmsLogo.data.alt

                return (
                  <figure>
                    <Image
                      fluid={cmsLogo}
                      alt={cmsLogoAlt}
                      fadeIn={false}
                      loading="eager"
                    />
                  </figure>
                )
              })}
            </div>
          </div>
        </section>

        <section className="technologie-brand-logos u-margin-top-big u-center-text">
          <div className="row u-no-margin-bottom">
            <div className="col-1-of-1 u-margin-top-small">
              <Title title="Static Site Generator" />
            </div>
          </div>

          <div className="row">
            <div className="custom-colomn">
              {staticSiteGenerator.map(ssg => {
                const cmsLogo = ssg.data.image.fluid
                const cmsLogoAlt = ssg.data.alt

                return (
                  <figure>
                    <Image
                      fluid={cmsLogo}
                      alt={cmsLogoAlt}
                      fadeIn={false}
                      loading="eager"
                    />
                  </figure>
                )
              })}
            </div>
          </div>
        </section>

        <section className="technologie-brand-logos u-margin-top-big u-center-text">
          <div className="row u-no-margin-bottom">
            <div className="col-1-of-1 u-margin-top-small">
              <Title title="Deployment Platform" />
            </div>
          </div>

          <div className="row">
            <div className="custom-colomn">
              {deploymentPlatform.map(deployment => {
                const cmsLogo = deployment.data.image.fluid
                const cmsLogoAlt = deployment.data.alt

                return (
                  <figure>
                    <Image
                      fluid={cmsLogo}
                      alt={cmsLogoAlt}
                      fadeIn={false}
                      loading="eager"
                    />
                  </figure>
                )
              })}
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  h3.heading-secondary {
    margin-bottom: 2rem;

    @media only screen and (max-width: 37.5em) {
      margin-bottom: 0;
    }
  }

  .custom-colomn {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .technologie-brand-logos {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  .technologie-brand-logos figure {
    max-width: 140px;
    margin: 0 2.325rem 1.55rem;
    position: relative;
    width: 100%;
  }
`

export default TechnologiesWeUse
