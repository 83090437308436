import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import Title from "./Title"

const query = graphql`
  {
    allPrismicTestimonials(sort: { fields: data___number }) {
      nodes {
        data {
          image {
            alt
            fixed {
              ...GatsbyPrismicImageFixed_withWebp
            }
          }
          info {
            text
          }
          name {
            text
          }
          position {
            text
          }
        }
      }
    }
  }
`

const Testimonials = () => {
  const {
    allPrismicTestimonials: { nodes: testimonails },
  } = useStaticQuery(query)

  return (
    <section id="testimonails" className="section u-padding">
      <div className="u-header-wrap">
        <div className="row">
          <div className="col-1-of-1 u-margin-top-big">
            <Title title="Kind words by nice people" />
            <h2 className="heading-primary heading-primary--main">
              I listen to your needs, ensuring you get a product you’re happy
              with. Here’s what some of them have said.
            </h2>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-2-of-2">
          {testimonails &&
            testimonails.map(testimonail => {
              const info = testimonail.data.info.text
              const name = testimonail.data.name.text
              const position = testimonail.data.position.text
              const image = testimonail.data.image.fixed
              const alt = testimonail.data.image.alt
              const uid = testimonail.data.uid

              return (
                <article className="testimonails" key={uid}>
                  <p>{info}</p>
                  <div className="testimonails-footer">
                    <figure>
                      <Image
                        fixed={image}
                        alt={alt}
                        className="avatar"
                        fadeIn={false}
                        loading="eager"
                      />
                    </figure>
                    <footer className="testimonails__footer--info">
                      <p>{name}</p>
                      <p>{position}</p>
                    </footer>
                  </div>
                </article>
              )
            })}
        </div>
      </div>
    </section>
  )
}

export default Testimonials
