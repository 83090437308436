import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import Title from "./Title"

const query = graphql`
  {
    services: allPrismicServices(sort: { order: ASC, fields: id }) {
      nodes {
        data {
          info {
            text
          }
          name {
            text
          }
          extra {
            text
          }
          tags {
            tag {
              text
            }
          }
          email {
            text
          }
          image {
            alt
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
          }
        }
        id
      }
    }
  }
`

export const Services = () => {
  const {
    services: { nodes: services },
  } = useStaticQuery(query)

  return (
    <section id="services" className="section u-padding">
      {/* <div className="u-header-wrap u-margin-bottom-big">
        <div className="row">
          <div className="col-1-of-1 u-margin-top-big">
            <Title title="Services" />
            <h2 className="heading-primary heading-primary--main">
              This is how we can help you get more customers
            </h2>
          </div>
        </div>
      </div> */}

      <div className="row">
        <section>
          <div className="col-2-of-2">
            {services &&
              services.map(service => {
                const { name, info, extra, tags, email, image } = service.data
                const fluid = image.fluid
                const alt = image.alt
                const { id } = service

                return (
                  <>
                    <article key={id} className="services__info">
                      <h2 className="heading-secondary heading-secondary--title u-margin-bottom-medium">
                        {name.text}
                      </h2>
                      <p className="paragraph">{info.text}</p>

                      <p className="paragraph">
                        {extra.text}
                        <span>
                          <a
                            href="mailto:{email.text}"
                            className="services__email-link"
                            aria-label="email link"
                          >
                            {email.text}
                          </a>
                        </span>
                      </p>
                      <div className="u-tags">
                        {tags &&
                          tags.map((tag, index) => {
                            return (
                              <span className="u-tag is-light" key={index}>
                                {tag.tag.text}
                              </span>
                            )
                          })}
                      </div>
                    </article>

                    <figure>
                      {fluid && (
                        <Image
                          fluid={fluid}
                          alt={alt}
                          fadeIn={false}
                          loading="eager"
                        />
                      )}
                    </figure>
                  </>
                )
              })}
          </div>
        </section>
      </div>
    </section>
  )
}

export default Services
