import React from "react"
import styled from "styled-components"

const Intro = () => {
  return (
    <Wrapper id="about-us">
      <div className="section u-padding-bottom">
        <div className="row">
          <div className="col-1-small u-margin-top-big">
            <h2 className="u-center-text">
              I use design and code to solve problems.
            </h2>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  h2 {
    color: #333;
    text-transform: none;
    font-size: 2.75em;
    font-weight: normal;
    line-height: 1.5;
    display: inline-grid;

    @media only screen and (max-width: 37.5em) {
      font-size: 2em;
    }

    span {
      color: #52b788;
    }
  }
`

export default Intro
