import React from "react"
import { graphql } from "gatsby"

import { Layout, Hero, Portfolio } from "../components"
import Seo from "../components/seo"
import CallToAction from "../components/CallToAction"
import Services from "../components/Sevices"
// import MarketingIntro from "../components/MarketingIntro"
import Navbar from "../components/Navbar"
import Blog from "../components/Blog"
import Calendly from "../components/Calendly"
import Intro from "../components/Intro"
import Testimonials from "../components/Testimonials"
import SolveProblems from "../components/SolveProblems"
import TechnologiesWeUse from "../components/TechnologiesWeUse"

export const query = graphql`
  {
    portfolioLists: allPrismicPortfolio(limit: 4) {
      nodes {
        id
        href
        uid
        data {
          image {
            alt
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
            url
          }
          info {
            text
          }
          link {
            target
            url
          }
          slug {
            text
          }
          title {
            text
          }
          type {
            text
          }
          technologies {
            technology {
              text
            }
          }
        }
      }
    }

    allPrismicPost(sort: { order: DESC, fields: data___post_date }, limit: 3) {
      nodes {
        data {
          post_author {
            text
          }
          post_author_image {
            alt
            dimensions {
              height
              width
            }
            fixed {
              ...GatsbyPrismicImageFixed_withWebp
            }
          }
          post_author_info {
            text
          }
          post_author_link {
            url
          }
          post_body {
            text
          }
          post_category
          post_date(formatString: "MMMM Do, YYYY")
          post_preview_description {
            text
          }
          post_title {
            text
          }
          slug {
            text
          }
          post_image_preview {
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
            alt
          }
        }
        id
        uid
      }
    }
  }
`

const HomePage = ({ page, data }) => {
  const {
    portfolioLists: { nodes: portfolios },
    allPrismicPost: { nodes: blogs },
  } = data
  return (
    <Layout>
      <>
        <Seo
          title="Web design & web development near me - Vermont Web Development"
          description="I use the latest tools to build everything from simple websites to eCommerce packages, so that no matter where you are in the world, I can give you a great website."
        />
        {!page && (
          <>
            <div className="u-no-padding">
              <Navbar />
            </div>
            <Hero />
          </>
        )}

        {/* <Hero projects={projects} /> */}
        <Intro />
        {/* <MarketingIntro /> */}
        <Services />
        <SolveProblems />
        <TechnologiesWeUse />
        <Testimonials />
        <Portfolio portfolios={portfolios} title="Our Portfolio" />
        <Blog blogs={blogs} />
        <CallToAction bigTitle="Ready to build something cool together?">
          <h2 className="heading-primary heading-primary--main u-margin-top-small">
            Need more sales? Let me design a new website that converts customers
            into buyers with ease. Talk to me today about increasing your sale!
          </h2>
          <Calendly />
        </CallToAction>
      </>
    </Layout>
  )
}

export default HomePage
