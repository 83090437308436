import React from "react"
import Image from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"
import { IoIosArrowRoundForward } from "react-icons/io"
import { HiOutlineArrowNarrowRight } from "react-icons/hi"

import Title from "./Title"

const Blog = ({ blogs }) => {
  return (
    <section className="section-line">
      <div className="blog row section u-margin-top u-margin-bottom-medium">
        <div className="u-header-wrap u-margin-bottom-big">
          <div className="row">
            <div className="col-1-of-1 u-margin-top-big">
              <Title title="Stories and updates" />
              <h2 className="heading-primary heading-primary--main">
                Read what’s new from the VT Web Development blog
              </h2>
            </div>
          </div>
        </div>

        <div className="col-3-of-3">
          {blogs &&
            blogs.map(item => {
              const { id } = item
              const {
                post_title,
                post_image_preview,
                post_date,
                post_author,
                post_preview_description,
                slug,
              } = item.data
              const fluid = post_image_preview.fluid

              const truncate =
                post_preview_description.text.length > 160
                  ? `${post_preview_description.text.substring(0, 90)}...`
                  : post_preview_description.text

              return (
                <BlogWrapper>
                  <article key={id}>
                    <Link to={`/blog/${slug.text}`}>
                      <figure>
                        <Image fluid={fluid} alt={post_image_preview.alt} />
                      </figure>
                    </Link>

                    <div className="blog__author">
                      <p className="paragraph">
                        {post_author.text} / <time>{post_date}</time>
                      </p>
                    </div>

                    <Link to={`/blog/${slug.text}`}>
                      <h3 className="heading-secondary--title u-margin-top-small u-margin-bottom-medium">
                        {post_title.text}
                      </h3>
                    </Link>

                    <p className="paragraph u-margin-bottom-medium">
                      {truncate}
                    </p>

                    <Link to={`/blog/${slug.text}`} className="paragraph__link">
                      Read More <IoIosArrowRoundForward />
                    </Link>
                  </article>
                </BlogWrapper>
              )
            })}
        </div>

        <div className="call-to-action-link u-center-text">
          <p className="paragraph u-margin-top">
            <HiOutlineArrowNarrowRight />
            <Link to="blog">View all posts</Link>
          </p>
        </div>
      </div>
    </section>
  )
}

const BlogWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  margin-bottom: 2rem;

  @media screen and (max-width: 990px) {
    height: 100%;
  }

  .gatsby-image-wrapper {
    background-size: cover;
    height: 23rem;
  }

  .heading-secondary--title {
    color: #000;
    font-size: 2.5rem;

    &:hover {
      color: #52b788;
      transition: all 0.8s;
    }
  }

  .paragraph__link {
    color: #000;
    font-size: 1.6rem;

    svg {
      vertical-align: middle;
    }

    &:hover {
      color: #52b788;
      transition: all 0.8s;
    }
  }

  .paragraph {
    color: #777;
    margin-top: 5rem;
  }

  .blog__author {
    .paragraph,
    time {
      color: #777;
      font-size: 1.25rem;
    }
  }
`
export default Blog
