import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Title from "./Title"

const query = graphql`
  {
    allPrismicSolveProblems(sort: { fields: data___number }) {
      nodes {
        data {
          info {
            text
          }
          title {
            text
          }
          image {
            alt
            url
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
          }
        }
        uid
      }
    }
  }
`

const SolveProblems = () => {
  const {
    // image: { nodes: image },
    allPrismicSolveProblems: { nodes: solveProblems },
  } = useStaticQuery(query)

  return (
    <section className="section background-light u-padding">
      <div className="u-header-wrap u-margin-bottom-big">
        <div className="row">
          <div className="col-1-of-1 u-margin-top-big">
            <Title title="How do I work" />
            <h2 className="heading-primary heading-primary--main">
              My creativity helps make your idea look stunning and I love to
              create simple things that work.
            </h2>
          </div>
        </div>
      </div>

      <div className="row">
        {/* <div className="col-3-of-3"> */}
        <div className="col-3-of-3-col">
          {solveProblems &&
            solveProblems.map(solveProblem => {
              const title = solveProblem.data.title.text
              const info = solveProblem.data.info.text
              const uid = solveProblem.data.uid
              const image = solveProblem.data.image.fluid
              const alt = solveProblem.data.image.alt

              return (
                <Wrapper>
                  <div className="border-radius">
                    <figure key={uid}>
                      <Image
                        fluid={image}
                        alt={alt}
                        fadeIn={false}
                        loading="eager"
                      />
                    </figure>
                  </div>
                  <article>
                    <h3 className="heading-secondary heading-secondary--title u-margin-bottom-medium">
                      {title}
                    </h3>
                    <p>{info}</p>
                  </article>
                </Wrapper>
              )
            })}
        </div>
      </div>
    </section>
  )
}

const Wrapper = styled.article`
  float: left;
  margin-bottom: 6rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-row-gap: 1.5rem;
  grid-column-gap: 2.5rem;

  .border-radius {
    border-radius: 30%;
    border: 2px solid hsl(0deg 0% 13%);
    width: 70px;
    height: 70px;
    padding: 1.5rem;
  }
`

export default SolveProblems
